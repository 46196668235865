import React from 'react';
import PropTypes from 'prop-types';
import { Card, TextBlock, Title } from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import Flag from './flag';

const Header = styled.div`
  display: flex;
  gap: var(--spacing-large);
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 835px) and (max-width: 1069px) {
    ${({ collapse }) => collapse && `
      flex-direction: column-reverse;
      align-items: center;
    `}
  }

  @media screen and (max-width: 414px) {
    flex-direction: column-reverse;
    gap: var(--spacing-medium);

    div {
      width: 100%;
    }
  }
`;

const Group = styled.div`

`;

const Name = styled(Title)`
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  margin: 0px;
  text-align: left;
  width: 100%;
`;

const Organisation = styled(TextBlock)`
  font-weight: 900;
  text-align: left;
  font-style: italic;
  width: 100%;
`;

const Description = styled(TextBlock)`
  margin-top: var(--spacing-small) !important;
  margin-bottom: auto;
`;

const GrantCard = ({
  country, title, organisation, children, backgroundColor, collapse
}) => (
  <Card backgroundColor={backgroundColor}>
    <Header collapse={collapse}>
      <Group>
        <Name as="h4">{title}</Name>
        <Organisation>
          By
          {' '}
          {organisation}
          ,
          {' '}
          {country}
        </Organisation>
      </Group>
      <Flag name={country} />
    </Header>
    <Description>
      {children}
    </Description>
  </Card>
);

GrantCard.propTypes = {
  country: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  organisation: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backgroundColor: PropTypes.string,
  collapse: PropTypes.bool,
};

GrantCard.defaultProps = {
  backgroundColor: 'white',
  collapse: false,
};

export default GrantCard;
